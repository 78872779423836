import { useFcmStore } from "./stores/fcmStore";
import { getToken } from "firebase/messaging";
import { messaging } from "@/firebase";

export default function registerServiceWorker() {
  const functionName = "registerServiceWorker";
  console.info(functionName);

  const fcmStore = useFcmStore();

  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.debug(
        functionName,
        "Service worker registered with scope:",
        registration.scope,
      );

      // Check if the page is already controlled by the service worker
      if (navigator.serviceWorker.controller) {
        console.debug(
          functionName,
          "Service worker is already controlling the page.",
        );
      } else {
        console.debug(
          functionName,
          "Service worker is not controlling the page yet.",
        );

        // Listen for when the service worker starts controlling the page
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          console.log(
            functionName,
            "Service worker is now controlling the page.",
          );
          // Optionally reload the page
          window.location.reload();
        });
      }

      // Wait until the service worker is fully activated and ready
      return navigator.serviceWorker.ready;
    })
    .then((registration) => {
      console.debug(functionName, "Service worker is ready:", registration);

      console.debug(
        functionName,
        "Notification permission:",
        Notification.permission,
      );

      return getToken(messaging, { vapidKey: process.env.VUE_APP_VAPID_KEY });
    })
    .then((currentToken) => {
      console.debug(functionName, "currentToken:", currentToken);
      if (currentToken) {
        console.debug(functionName, "FCM token received:", currentToken);
        fcmStore.fcmToken = currentToken;
        // Handle the token (e.g., send it to your server)
      } else {
        console.debug(functionName, "No registration token available.");
      }
    })
    .catch((err) => {
      console.error(
        functionName,
        "Service worker registration or messaging setup failed:",
        err,
      );
    });
}
