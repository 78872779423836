<template>
  <v-snackbar
    close-on-content-click
    location="top"
    v-model="newStoryStore.snackbar"
    :timeout="1750"
    :color="newStoryStore.postSuccess == true ? 'success' : 'error'"
  >
    {{
      newStoryStore.postSuccess == true
        ? "Story posted"
        : "Failed to post story"
    }}
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click="newStoryStore.snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    data-testid="snackbar"
    close-on-content-click
    location="top"
    v-model="snackBarStore.snackbar"
    :timeout="snackBarStore.timeout"
    :color="snackBarStore.color"
    @click="handleLink"
    :style="{ cursor: snackBarStore.url ? 'pointer' : 'default' }"
  >
    {{ snackBarStore.message }}
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click.stop="snackBarStore.snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { useNewStoryStore } from "@/stores/newStoryStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { useRouter } from "vue-router";

const componentName = "SnackBar";

export default {
  setup() {
    const router = useRouter();
    const newStoryStore = useNewStoryStore();
    const snackBarStore = useSnackBarStore();

    const handleLink = () => {
      const functionName = "handleLink";
      console.info(functionName);
      console.info(
        functionName,
        "messageType:",
        snackBarStore.messageType,
        "URL:",
        snackBarStore.url,
      );
      if (snackBarStore.messageType != null && snackBarStore.url != null) {
        switch (snackBarStore.messageType) {
          case "requestStory": {
            const url = new URL(snackBarStore.url);
            const pathname = url.pathname;
            router.push(pathname);
            break;
          }
          case "newStory":
            redirectToStoryPage(
              snackBarStore.extractStoryIdFromUrl(snackBarStore.url),
            );
            break;
        }
      } else {
        console.error(
          componentName,
          functionName,
          "MessageType and/or URL missing from notification",
        );
        return;
      }
    };

    const redirectToStoryPage = (storyId) => {
      const functionName = "redirectToStoryPage";
      console.info(componentName, functionName);

      if (!storyId) {
        console.error(componentName, functionName, "StoryId not provided");
        return;
      }

      router.push({
        name: "posts",
        params: {
          postId: storyId,
        },
      });
    };

    return { newStoryStore, snackBarStore, handleLink };
  },
};
</script>
