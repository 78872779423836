import { useAuthStore } from "@/stores/authStore";

const functionName = "webAuthnSendCredential";

async function webAuthnSendCredential(challenge, credential, username) {
  console.log("webAuthSendCredential");
  console.debug(
    functionName,
    "challenge:",
    challenge,
    "credential:",
    credential,
    "username:",
    username,
  );

  const authStore = useAuthStore();

  // Get UserId from credential-object and delete the key afterward
  const userId = credential.userId;
  delete credential.userId;

  console.debug(functionName, "userId:", userId);

  try {
    let response = await fetch(
      window?.location?.hostname == "localhost"
        ? "http://localhost:5001/storyque-fee51/us-central1/api/registerUser"
        : "https://api-efmmhglqbq-uc.a.run.app/registerUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          challenge: challenge,
          registration: credential,
          userId: userId,
          username: username,
        }),
      },
    );

    if (response.ok) {
      response = await response.json();
      console.debug(functionName, "response:", response);

      if (!response.status) {
        console.warn(functionName, "status:", response.status);
        return null;
      } else {
        authStore.setUserId(response.userId);
        authStore.setUsername(response.username);
      }
      return { userId: response.userId, username: response.username };
    } else {
      return null;
    }
  } catch (error) {
    console.error(functionName, error);
    return null;
  }
}

export default { webAuthnSendCredential };

if (process.env.VUE_APP_E2E && typeof window !== "undefined") {
  window.webAuthnSendCredential = webAuthnSendCredential;
}
