<template>
  <v-bottom-navigation
    fixed
    v-if="displayNavBar"
    :elevation="0"
    mode="shift"
    v-model="activeTab"
    :style="{
      borderTop: '1px solid lightgrey',
      opacity: displayNavBar ? 1 : 0,
      pointerEvents: displayNavBar ? 'all' : 'none',
    }"
    height="48"
  >
    <v-btn
      @click="changeTab(0)"
      to="/posts/create"
      data-testid="bottom-navigation-tab-create"
    >
      <v-icon>mdi-plus-box-outline</v-icon>
      <span>Create</span>
    </v-btn>
    <v-btn
      @click="changeTab(1)"
      to="/profile"
      data-testid="bottom-navigation-tab-profile"
    >
      <v-icon>mdi-home</v-icon>
      <span>Home</span>
    </v-btn>
    <v-btn
      @click="changeTab(2)"
      to="/privateSharedPosts"
      data-testid="bottom-navigation-tab-storiesSharedToYou"
      :disabled="friendStore.acceptedFriendRequest == null"
    >
      <v-icon>mdi-message-text</v-icon>
      <span>Private</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { useDimenStore } from "@/stores/dimenStore";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useFriendStore } from "@/stores/friendStore";

const componentName = "BottomNavigation";

export default {
  name: "BottomNavigation",
  setup() {
    const dimenStore = useDimenStore();
    const displayNavBar = ref(false);
    // sTVYVoEe -->
    const friendStore = useFriendStore();
    // <--

    const route = useRoute();

    const activeTab = ref(0);

    const changeTab = (index) => {
      activeTab.value = index;
    };

    // Check if current route requires v-bottom-navigation to be displayed -->
    watch(
      () => route.meta,
      (newMeta) => {
        console.debug(componentName, "newMeta:", newMeta);
        displayNavBar.value = newMeta.displayBotNav || false;

        // Try to get v-bottom-navigation's height in case it's not already done
        if (!dimenStore.vBotNavHeight) {
          try {
            const computedStyle = window.getComputedStyle(
              document.querySelector(".v-bottom-navigation"),
            );

            dimenStore.setVBotNavHeight(parseInt(computedStyle.height));
          } catch (error) {
            console.error(error);
          }
        }
      },
      { flush: "pre", immediate: true, deep: false },
    );
    // <--

    // Get current route's name so it can be automatically activated in case app is just launched in specific route -->
    watch(
      () => route.name,
      (newRouteName) => {
        console.debug(componentName, "newRouteName:", newRouteName);

        switch (newRouteName) {
          case "create":
            activeTab.value = 0;
            break;
          case "profile":
            activeTab.value = 1;
            break;
          case "storiesSharedToYou":
            activeTab.value = 2;
            break;

          default:
            activeTab.value = 1;
            break;
        }
      },
      { flush: "pre", immediate: true, deep: false },
    );
    // <--

    onMounted(async () => {
      const functionName = "onMounted";
      console.info(componentName, functionName);
    });

    return {
      displayNavBar,
      activeTab,
      changeTab,
      friendStore,
    };
  },
};
</script>

<style>
.firebase-emulator-warning {
  background-color: transparent !important;
  border: none !important;
  color: rgba(245, 66, 66, 0.3) !important;
  pointer-events: none;
}
</style>
