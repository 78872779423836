import { firestore } from "@/firebase";
import { useAuthStore } from "@/stores/authStore";
import {
  addDoc,
  collection,
  query,
  where,
  writeBatch,
  getDocs,
} from "firebase/firestore";

const functionName = "createFriendRequestLink";

/**
 * code - UUIDv4 used for generating friend request URL to create friendship between two users
 */

export default async function (code) {
  console.info(functionName);
  const authStore = useAuthStore();

  // Delete any previous Friend Requests
  try {
    const q = query(
      collection(firestore, "friendRequests"),
      where("creatorId", "==", authStore.userId),
    );
    const querySnapshot = await getDocs(q);

    const batch = writeBatch(firestore);

    if (querySnapshot.empty) {
      console.debug(functionName, "No previous/old Friend Request(s) found");
    } else {
      querySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      console.debug(functionName, "Previous/old Friend Request(s) deleted");
    }
  } catch (error) {
    console.error(
      functionName,
      "Error occurred when deleting previous/old Friend Request(s): ",
      error,
    );
  }

  // Create new Friend Request
  const friendRequestLinksCollectionRef = collection(
    firestore,
    "friendRequests",
  );

  const friendRequestLinkDocData = {
    code: code,
    createdAt: new Date().toUTCString(),
    updatedAt: null,
    creatorId: authStore.userId,
    creatorName: authStore.username,
    receiverId: null,
    receiverName: null,
    isAccepted: "pending",
    userIds: [authStore.userId],
  };

  console.debug(
    functionName,
    "friendRequestLinkDocData:",
    JSON.stringify(friendRequestLinkDocData),
  );

  try {
    const friendRequestDocRef = await addDoc(
      friendRequestLinksCollectionRef,
      friendRequestLinkDocData,
    );
    console.log(
      functionName,
      `Friend Request -document created with documentID: '${friendRequestDocRef.id}'`,
    );
    return { status: true, message: "Friend request created successfully" };
  } catch (error) {
    return { status: false, message: "Failed to create friend request" };
  }
}
