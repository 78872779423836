<template>
  <div data-testid="story-viewer">
    <div
      v-if="
        story == null || containerHeight == null || revealedStoryTexts == null
      "
    >
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </div>
    <div
      v-else
      :style="{
        display: 'flex',
        flexDirection: 'column',
        height: dimenStore.isMobile ? `${containerHeight}px` : `1000px`,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }"
    >
      <v-carousel
        touch
        v-model="currentSlide"
        :continuous="false"
        hide-delimiters
        height="100%"
        show-arrows="true"
      >
        <v-carousel-item
          v-for="(storyPage, index) in story.pages"
          :key="storyPage.id"
          :style="{
            display: 'flex',
            height: '100%',
            width: dimenStore.isMobile ? `100%` : '500px',
            alignItems: center,
            justifyContent: center,
          }"
        >
          <v-img
            :data-testid="`story-viewer-media#${index}`"
            :width="dimenStore.isMobile ? `100%` : '500px'"
            :src="storyPage.mediaUrl"
          >
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
          </v-img>
          <div
            :style="{
              height: `${storyPageNumberIndicator}px`,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }"
          >
            <p style="margin: auto; font-weight: bold; font-size: large">
              {{ `${index + 1} / ${story.pages.length}` }}
            </p>
          </div>
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: '5px',
            }"
          >
            <div
              :class="`text-container ${textBoxScrollabilityShadow}`"
              :style="{
                width: dimenStore.isMobile ? `100%` : '500px',
                height: `${storyPageTextHeight}px`,
                padding: '5px',
                textAlign: 'left',
              }"
              ref="textBoxRef"
              @scroll="handleScroll"
              lang="fi"
            >
              <p :data-testid="`story-viewer-text#${index}`">
                {{ revealedStoryTexts[currentSlide] }}
              </p>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
      <!-- <div
      v-if="!authStore.user"
      style="align-self: flex-end; margin-top: 25px; width: 100%"
    >
      <v-btn
        id="activator-target"
        prepend-icon="mdi-login"
        width="100%"
        variant="plain"
        style="background-color: #f8f9fa; border-radius: 10px"
        @click="authenticationPopupOpened = true"
      >
        Login
      </v-btn>
      <v-dialog
        v-model="authenticationPopupOpen"
        activator="#activator-target"
        max-width="350"
      >
        <template v-slot:default="{ isActive }">
          <v-card prepend-icon="mdi-login" title="Login">
            <v-card-text>
              It looks like you are viewing this content without being logged
              in.<br /><br />To receive notifications when stories are being
              shared with you, please register or login.
            </v-card-text>
            <template v-slot:actions>
              <v-btn
                color="primary"
                variant="tonal"
                class="ml-auto"
                text="Authenticate"
                @click="redirectToAuthenticationAndBackToStory()"
              ></v-btn>
              <v-btn
                class="ml-auto"
                text="Close"
                @click="isActive.value = false"
              ></v-btn>
            </template>
          </v-card>
        </template>
      </v-dialog>
    </div> -->
    </div>
  </div>
</template>

<script>
import { getPreviousRoute } from "@/router";
import { useAppBarStore } from "@/stores/appBarStore";
import { useAuthStore } from "@/stores/authStore";
import { useCurrentViewedStoryStore } from "@/stores/currentViewedStoryStore";
import { useDimenStore } from "@/stores/dimenStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import fetchStoryData from "@/utils/story/fetchStoryData";

const componentName = "StoryViewer";

const textRevealIntervalDefaultMs = 55;
const textRevealIntervalEoSMs = 1000;
const textRevealIntervalEoPMs = 500;

export default {
  name: componentName,
  components: {},
  setup() {
    const appBarStore = useAppBarStore();
    const dimenStore = useDimenStore();

    const containerHeight = ref(null);

    const snackBarStore = useSnackBarStore();
    const authStore = useAuthStore();

    const route = useRoute();
    const router = useRouter();

    const currentViewedStoryStore = useCurrentViewedStoryStore();
    const currentSlideIndex = ref(0);
    const story = ref(null);

    // Story media (image) -->
    const imgWidth = ref(null);
    const imgHeight = ref(null);
    // <--

    // Story page number indicator -->
    const storyPageNumberIndicator = ref(
      (window.innerHeight - dimenStore.vTopNavHeight) * 0.05,
    );
    // <--

    const storyPageTextHeight = ref(null);

    watch(
      () => containerHeight.value,
      (newContainerHeight) => {
        console.debug(componentName, "newContainerHeight:", newContainerHeight);

        imgWidth.value = ref(window.innerWidth);
        imgHeight.value =
          (newContainerHeight - dimenStore.vTopNavHeight) * 0.475;

        storyPageTextHeight.value =
          (newContainerHeight - dimenStore.vTopNavHeight) * 0.475;

        storyPageNumberIndicator.value =
          (newContainerHeight - dimenStore.vTopNavHeight) * 0.05;
      },
      { flush: "pre", immediate: false, deep: false },
    );

    // Animated text
    const revealedStoryTexts = ref(null);
    const textRevealInterval = ref(null);
    const textRevealIntervalMs = ref(textRevealIntervalDefaultMs);

    // Text box auto scrolling
    const textBoxRef = ref(null);
    const textBoxAutoscrolling = ref(true);

    // Text box shadows
    const textBoxScrollabilityShadow = ref("");

    // const authenticationPopupOpen = ref(false);
    // const authenticationPopupOpened = ref(false);

    watch(currentSlideIndex, (newCurrentSlideIndex, oldCurrentSlideIndex) => {
      console.log(
        componentName,
        `myValue changed from ${oldCurrentSlideIndex} to ${newCurrentSlideIndex}`,
      );

      // Disable line below for INSTANT STORY TEXT
      // Reset interval on slide change
      if (textRevealInterval.value !== null) {
        clearInterval(textRevealInterval.value);
        textRevealInterval.value = null;
      }
      textRevealIntervalMs.value = textRevealIntervalDefaultMs;
      storyTextRevealInIntervalsForStoryPageIndex(newCurrentSlideIndex);
    });

    const handleScroll = () => {
      console.debug(componentName, "handleScroll");
      const container = textBoxRef.value;
      if (!container) return;

      textBoxScrollabilityShadow.value = getScrollability();
    };

    const getScrollability = () => {
      if (!textBoxRef.value) {
        return "";
      }

      let className = "";

      const container = textBoxRef.value;
      const scrollableToTop = container.scrollTop !== 0;
      const scrollableToBottom =
        container.scrollHeight - container.scrollTop !== container.clientHeight;

      if (scrollableToTop && scrollableToBottom) {
        className = "scrollable-to-top-and-bottom";
      } else if (scrollableToTop && !scrollableToBottom) {
        className = "scrollable-to-top";
      } else if (!scrollableToTop && scrollableToBottom) {
        className = "scrollable-to-bottom";
      }

      if (!className.includes("bottom")) {
        textBoxAutoscrolling.value = true;
      } else {
        textBoxAutoscrolling.value = false;
      }

      return className;
    };

    const storyTextRevealInIntervalsForStoryPageIndex = (index) => {
      // if (textRevealInterval.value !== null) {
      //   clearInterval(textRevealInterval.value);
      //   textRevealInterval.value = null;
      // }

      textRevealInterval.value = setInterval(() => {
        // Get next character in text
        const nextCharacter = story.value.pages[index].text.charAt(
          revealedStoryTexts.value[index].length,
        );

        const nextNextCharacter = story.value.pages[index].text.charAt(
          revealedStoryTexts.value[index].length + 1,
        );

        let updateIntervalMs = false;

        // In case of current sentence is ending but there will be next one (whitespace or letter after dot (.))
        if (
          nextCharacter === "." &&
          nextNextCharacter.match(/\s|[a-zA-Z]/) !== null
        ) {
          // Reset current interval, start a new one with new interval ms
          updateIntervalMs = true;
          textRevealIntervalMs.value = textRevealIntervalEoSMs;
          // In case of current sentence is continuing but there is a comma
        } else if (
          nextCharacter === "," &&
          nextNextCharacter.match(/\s|[a-zA-Z]/) !== null
        ) {
          updateIntervalMs = true;
          textRevealIntervalMs.value = textRevealIntervalEoPMs;
        } else if (textRevealIntervalMs.value !== textRevealIntervalDefaultMs) {
          updateIntervalMs = true;
          textRevealIntervalMs.value = textRevealIntervalDefaultMs;
        }

        revealedStoryTexts.value[index] += nextCharacter;

        try {
          // Auto scroll
          if (textBoxRef.value && textBoxAutoscrolling.value) {
            textBoxRef.value.scrollTop = textBoxRef.value.scrollHeight;
          }
        } catch (error) {
          console.error(componentName, error);
        }

        if (updateIntervalMs) {
          clearInterval(textRevealInterval.value);
          textRevealInterval.value = null;
          storyTextRevealInIntervalsForStoryPageIndex(currentSlideIndex.value);
        }
      }, textRevealIntervalMs.value);
    };

    const goBack = () => {
      const functionName = "goBack";
      console.info(componentName, functionName);
      console.debug(
        componentName,
        functionName,
        "Previous route: ",
        getPreviousRoute(),
      );

      currentViewedStoryStore.unsetStory();
      router.replace("/home");
    };

    // const redirectToAuthenticationAndBackToStory = () => {
    //   const functionName = "redirectToAuthenticationAndBackToStory";
    //   console.info(functionName);
    //   authenticationPopupOpen.value = false;
    //   router.push({
    //     name: "auth",
    //     query: { redirect: window.location.pathname },
    //   });
    // };

    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        currentSlideIndex.value =
          (currentSlideIndex.value + 1) % story.value.pages.length;
      } else if (event.key === "ArrowLeft") {
        currentSlideIndex.value =
          (currentSlideIndex.value - 1 + story.value.pages.length) %
          story.value.pages.length;
      }
    };

    const formatStoryText = (storyData) => {
      const functionName = "formatStoryText";
      console.info(componentName, functionName);
      // Add whitespaces after end of sentences if not already exist
      for (let i = 0; i < storyData.pages.length; i++) {
        storyData.pages[i].text = storyData.pages[i].text.replace(
          /\.(?!\s)/g,
          ". ",
        );
      }
    };

    onMounted(async () => {
      const functionName = "onMounted";

      dimenStore.calculateIsMobile();
      dimenStore.calculateTopNavHeight();

      containerHeight.value = dimenStore.calculateContainerHeight(true, false);

      window.addEventListener("resize", () => {
        containerHeight.value = dimenStore.calculateContainerHeight(
          true,
          false,
        );
      });

      window.addEventListener("keydown", handleKeyDown);

      const postId = route?.params?.postId;
      console.debug(componentName, functionName, "postId:", postId);

      if (postId === undefined || story.value === null) {
        console.debug(
          componentName,
          functionName,
          "currentViewedStoryStore is empty; Probably watching a shared story",
        );
        let storyData = await fetchStoryData(postId);
        console.debug(
          componentName,
          functionName,
          "storyData:",
          JSON.stringify(storyData),
        );

        if (storyData === null) {
          snackBarStore.displayNotification({
            message: "Story not found",
            color: "error",
          });
          setTimeout(() => {
            router.replace("/");
            return;
          }, 1000);

          return;
        }

        appBarStore.contentCreatorName =
          storyData?.creatorName == authStore.username
            ? "You"
            : storyData.creatorName;

        // Create an array of to-be-animated texts (strings)
        revealedStoryTexts.value = new Array(storyData.pages.length).fill("");
        storyTextRevealInIntervalsForStoryPageIndex(0);

        formatStoryText(storyData);
        story.value = storyData;
      }

      // // Authentication popup -->
      // setTimeout(() => {
      //   const displayAuthPopup = localStorage.getItem("displayAuthPopup");
      //   if (
      //     !authStore.user &&
      //     !authenticationPopupOpened.value &&
      //     !authenticationPopupOpen.value &&
      //     (displayAuthPopup == null || displayAuthPopup == true)
      //   ) {
      //     authenticationPopupOpen.value = true;
      //     localStorage.setItem("displayAuthPopup", false);
      //   }
      // }, 5000);
      //
    });

    onUnmounted(async () => {
      // Populate v-app-bar in parent component -->
      // <--

      clearInterval(textRevealInterval.value);
      textRevealInterval.value = null;
      window.removeEventListener("resize", () => {
        containerHeight.value = dimenStore.calculateContainerHeight(
          true,
          false,
        );
      });
      window.removeEventListener("keydown", handleKeyDown);

      // Reset v-app-bar -->
      appBarStore.title = null;
      appBarStore.back = null;
      // <--
    });

    return {
      handleScroll,
      textBoxScrollabilityShadow,
      textBoxRef,
      dimenStore,
      // authStore,
      goBack,
      story,
      revealedStoryTexts,
      currentSlide: currentSlideIndex,
      // // Authentication popup -->
      // authenticationPopupOpen,
      // authenticationPopupOpened,
      // redirectToAuthenticationAndBackToStory,
      // -->
      imgWidth,
      imgHeight,
      storyPageNumberIndicator,
      storyPageTextHeight,
      containerHeight,
    };
  },
};
</script>

<style scoped>
.flex-item {
  flex: 1;
}

.scroll-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px; /* Set your preferred shadow height */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
}

.text-container {
  white-space: wrap;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: normal;
  padding-left: 5px;
  padding-right: 5px;
  background-color: whitesmoke;
  border-radius: 5px;
}

.scrollable-to-top {
  box-shadow: inset 0px 15px 8px -10px #ccc;
}

.scrollable-to-bottom {
  box-shadow: inset 0px -15px 8px -10px #ccc;
}

.scrollable-to-top-and-bottom {
  box-shadow: inset 0px 15px 8px -10px #ccc, inset 0px -15px 8px -10px #ccc;
}

.v-app-bar-title {
  user-select: none;
  pointer-events: none;
}
</style>

<style>
.v-window__left,
.v-window__right {
  opacity: 0.5;
}

.v-carousel__controls .v-icon {
  font-size: 12px !important;
}

.v-carousel__controls {
  border-radius: 50px !important;
  background-color: rgba(66, 66, 66, 0) !important;
}

.v-carousel__controls > .v-btn--active > .v-btn__overlay {
  opacity: 0.5;
  background-color: #212529 !important;
}

.v-carousel__controls__item > .v-btn__content > i {
  opacity: 0.85;
  color: #ffffff !important;
}

.content-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
  gap: 10px; /* Space between items */
}
</style>
