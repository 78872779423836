import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getMessaging } from "firebase/messaging";

const fileName = "firebase.js";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

const getFirestoreInstance = () => {
  if (
    window?.location?.hostname === "localhost" ||
    window?.location?.hostname === "www.storyque.app"
  ) {
    return "";
  } else if (window?.location?.hostname === "www.storyque.xyz") {
    return "storyque-dev";
  }
};

const firestoreInstanceName = getFirestoreInstance();

// Export Firebase services
export const auth = getAuth();
export const firestore = getFirestore(firestoreInstanceName);
export const functions = getFunctions();
export const messaging = getMessaging();

console.debug("process.env.VUE_APP_E2E: ", process.env.VUE_APP_E2E);

// Use emulators during development
if (window?.location?.hostname === "localhost") {
  console.debug(fileName, "using Firebase Emulator Suite");
  connectFirestoreEmulator(
    firestore,
    "localhost",
    process.env.VUE_APP_E2E ? 8083 : 8080,
  );
  connectAuthEmulator(
    auth,
    process.env.VUE_APP_E2E ? "http://localhost:9100" : "http://localhost:9099",
    {
      disableWarnings: true,
    },
  );
  connectFunctionsEmulator(
    functions,
    "localhost",
    process.env.VUE_APP_E2E ? 5002 : 5001,
  );
} else {
  console.debug(
    fileName,
    `using '${firestoreInstanceName}' Firestore instance`,
  );
}
