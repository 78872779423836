import { client } from "@passwordless-id/webauthn";
import { v4 as uuidv4 } from "uuid";

const functionName = "webAuthnCreateCredential";

async function webAuthnCreateCredential(challenge, username) {
  console.log("createCredential");
  console.debug(
    functionName,
    "challenge: ",
    challenge,
    ", username: ",
    username,
  );

  if (!client.isAvailable()) {
    console.warn(functionName, "WebAuthn protocol not available");
    return { status: false, message: "WebAuthn protocol not available" };
  }

  const isLocalAuthenticator = await client.isLocalAuthenticator();
  if (!isLocalAuthenticator) {
    console.warn(functionName, "Device can't be used as authenticator");
    return { status: false, message: "Device can't be used as authenticator" };
  }

  const userId = uuidv4();
  console.debug(functionName, "generated userId:", userId);

  try {
    const registration = await client.register(username, challenge, {
      authenticatorType: "auto",
      userVerification: "required",
      timeout: 60000,
      attestation: false,
      userHandle: userId,
      debug: true,
    });

    console.debug(functionName, "registration", registration);
    return {
      status: true,
      registration: registration,
      userId: userId,
      message: "Registration successful",
    };
  } catch (error) {
    console.error(functionName, "error: ", error);
    return {
      status: false,
      registration: null,
      message: "Error occurred when registering",
      info: error,
    };
  }
}

export default { webAuthnCreateCredential };

if (process.env.VUE_APP_E2E && typeof window !== "undefined") {
  window.webAuthnCreateCredential = webAuthnCreateCredential;
}
